import React from 'react'

import { Box, Button } from './style'
import Agenda from '@interco/icons/build-v4/orangeds/MD/agenda'
import CellMoney from '@interco/icons/build-v4/bidis/v2/pagamentos/cell-money'

import UTMLink from 'src/components/CallToAction/ActionButtons/UTMLink'
import { sendDataLayerEvent } from 'src/shared/helpers'

const YourCredit = () => {
  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row d-flex justify-content-between align-items-center'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-20 lh-25 fs-lg-40 lh-lg-50  text-green--500 fw-600 mb-4'>
              <span className='d-block'>Seu crédito com</span>  as melhores taxas e parcelas!
            </h2>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--400'>Você procura pela menor parcela ou pela menor taxa? Simule e veja que no empréstimo com garantia de imóvel você consegue as duas coisas de uma vez só. Confira nossas opções de taxa:</p>
            <Button className='d-none d-md-block'>
              <UTMLink
                link='SIMULADOR_HOME_EQUITY_PJ'
                text='Simular Home Equity'
                campanha='site-pj'
                btncolor='button-link text-white rounded-2 mt-2 mb-5 mb-md-0'
                sendDatalayerEvent={sendDataLayerEvent}
                dobra='dobra_03'
                sectionName='Seu crédito com as melhores taxas e parcelas!'
              />
            </Button>
          </div>
          <div className='col-12 col-md-6'>
            <Box>
              <div className='d-flex'>
                <Agenda height={27} width={27} color='#161616' />
                <div className='w-100 ml-2'>
                  <p className='fs-18 lh-28 text-grayscale--400 fw-700'>Prazo</p>
                  <div className='d-flex justify-content-between'>
                    <p className='fs-16 lh-19 text-grayscale--400  mb-0'>SAC: </p>
                    <p className='fs-16 lh-19 text-grayscale--800 fw-600 mb-0'> 240 meses</p>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <p className='fs-16 lh-19 text-grayscale--400 mb-0'>Tabela Price: </p>
                    <p className='fs-16 lh-19 text-grayscale--800 fw-600 mb-0'>180 meses</p>
                  </div>
                </div>
              </div>
              <hr className='my-5' />
              <div className='d-flex'>
                <CellMoney height={27} width={27} color='#161616' />
                <div className='w-100 ml-2'>
                  <p className='fs-18 lh-28 text-grayscale--400 fw-700 mb-0'>Juros</p>
                  <p className='fs-16 lh-19 text-grayscale--400 mb-0'>a partir de 1,09% a.m. + IPCA</p>
                </div>
              </div>
            </Box>
            <Button className='d-block d-md-none'>
              <UTMLink
                link='SIMULADOR_HOME_EQUITY_PJ' text='Simular Home Equity' campanha='site-pj'
                btncolor='button-link text-white rounded-2 mt-2 mb-5 mb-md-0'
                sendDatalayerEvent={sendDataLayerEvent}
                dobra='dobra_02'
                sectionName='Seu crédito com as melhores taxas e parcelas!'
              />
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default YourCredit
