import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Box = styled.div`
  border: 1px solid #DEDFE4;
  border-radius: 20px;
  width: 100%;
  padding: 38px ;


  @media (min-width: ${breakpoints.md}){
    padding: 42px ;
  }

  @media (min-width: ${breakpoints.xl}){
    padding: 55px 62px;
  }

`
export const Button = styled.div`
    margin-top: 24px ;

    .button-link{
    background: #07605B ;
    width: 100% ;
    text-transform: inherit ;
    height: 48px ;

  }

`
