import React from 'react'

import ViewMore from 'src/components/ViewMore'
import Faq from 'src/components/Faq/index'

import pageContext from './../../pageContext.json'

const FAQ = () => {
  return (
    <section id='faq' className='bg-white pt-5 pb-md-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12  mb-4'>
            <h3 className='fs-24 lh-30 fs-lg-32 lh-gl-40 text-grayscale--500 fw-600'>Perguntas frequentes sobre Home Equity </h3>
          </div>
        </div>
        <ViewMore classwrapper='d-md-flex w-100 flex-wrap' color='white' height='700'>
          <Faq id='parceria' className='summary-content px-0' answerData={pageContext.structuredData.faq} />
        </ViewMore>
      </div>
    </section>
  )
}

export default FAQ
