import styled from 'styled-components'

export const Link = styled.div`

  .button-link{
    background: #07605B ;
    width: 100% ;
    text-transform: inherit ;
    height: 48px ;
  }



`
