import styled from 'styled-components'

export const Section = styled.section`
    background:#F5F6FA ;

`
export const Circle = styled.div`
    border-radius: 50%;
    background:#07605B ;
    width: 32px ;
    height: 32px ;
    display: flex ;
    justify-content: center ;
    align-items: center ;
    color: #fff ;
    margin-right: 23px ;
    min-width:32px ;

`
export const Button = styled.div`
    .button-link{
    background: #07605B ;
    width: 100% ;
    text-transform: inherit ;
    height: 48px ;
  }

`
