import React, { useState, useRef, useEffect } from 'react'
import useScroll from 'src/hooks/window/useScroll'

import { Container } from './style'
import menuJSON from './BarChart.json'

type AnimatedProps = {
  id: string;
  grid?: string;
}

type ContentDataProps = {
  name: string;
  colorName: string;
  percentage: string;
  colorPercentage?: string;
  color: string;
  width: string;
  border: string;
}

const BarChart = ({ id, grid }: AnimatedProps) => {
  const divRef = useRef<HTMLDivElement>(null)
  const scrollPositionY = useScroll(100)
  const contentData = menuJSON.find(item => item.id === id)
  const [ animatedClass, setAnimatedClass ] = useState('')

  useEffect(() => {
    const divPosition = divRef.current && divRef.current.getBoundingClientRect().y
    const divVisible = divPosition && divPosition * 1.1 <= window.innerHeight
    if (divVisible) setAnimatedClass('animated-chart')
  }, [ scrollPositionY ])

  return (
    <Container ref={divRef} className={` graph-container height-animate ${grid} ${animatedClass}`}>
      {contentData?.content.map((item: ContentDataProps) => (
        <>
          <div key={item.name} className='animate-content '>
            <div className='wrapper-chart d-flex align-items-center'>
              <div className='chart chart--animate mx-auto' style={{ background: item.color, width: item.width, maxWidth: item.width }} />
              <span className={`text-grayscale--400 fs-12 lh-13 fs-lg-14 fw-700 mb-2 text-center percentage-product ${item.border !== '' ? 'px-lg-1' : ''}`} style={{ background: item.border, color: item.colorPercentage }} dangerouslySetInnerHTML={{ __html: item.percentage }} />
            </div>
          </div>
          <span className='text-chart d-block px-0' style={{ color: item.colorName }}>{item.name}</span>
        </>
      ))}
    </Container>
  )
}

export default BarChart
