import styled from 'styled-components'

export const Section = styled.section`

.react-multiple-carousel__arrow {
      svg{
        fill: #07605B ;
      }
    }

    .react-multi-carousel-dot{

      button{
        background-color:#07605B !important;
      }
    }

    .react-multi-carousel-dot--active{

      button{
        background-color: #07605B !important;

      }
    }

`

export const Card = styled.div`
    background:#F5F6FA;
    border-radius: 8px ;
    padding: 24px ;
    height: 219px;
    width: 100% ;
    display: flex ;
    flex-direction: column ;
    justify-content: space-between ;


`
export const Button = styled.div`
    display: flex ;
    align-items: center ;
    justify-content: space-between ;
    width: 100% ;
    background: #07605B ;
    border-radius: 8px ;
    padding: 0 24px ;


    .event-caller{
      flex: 1 ;
    }

    .button-link{
    background: #07605B ;
    width: 100% ;
    text-transform: inherit ;
    height: 48px ;
    justify-content: start ;
  }

`
