/* eslint-disable react/jsx-no-bind */
import React, { useReducer, useEffect, useState, ChangeEvent } from 'react'
import { payrollSimulatorReducer, initialState, actions, PAYROLL_TYPE } from './PayrollSimulator.reducer'
import { getParameterByName, getCookie, sendDataLayerEvent } from 'src/shared/helpers'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { PayrollSimulatorProps, StateProps } from '../types'
import { BoxSimulador, ValueTitle, ValueWrapper, Cifra, InputValue, ValueDescription } from './style'

function currencyToFloat (source: string, currencyNumber: string) {
  const result =
    currencyNumber === ''
      ? 0
      : parseInt(currencyNumber.replace(/\D/g, '')) / 100
  return source === 'range' ? result * 100 : result
}

function floatToCurrency (floatNumber: number) {
  return floatNumber.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

const NewPayrollSimulatorHomeEquity = ({ page, isPj, sectionNumber, sectionName }: PayrollSimulatorProps) => {
  const [ state, dispatch ] = useReducer(payrollSimulatorReducer, initialState(page))

  const { simulationType, inputValue, percentage, maxValue }: StateProps = state

  const [ gclid, setGclid ] = useState<string | string[] | null | undefined>(null)
  const [ fbclid, setFbclid ] = useState<string | string[] | null | undefined>(null)
  const [ gaid, setGaid ] = useState<string | string[] | null | undefined>(null)
  const [ utmSource, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ utmContent, setUtmContent ] = useState<string | string[] | null | undefined>(null)
  const [ utmTerm, setUtmTerm ] = useState<string | string[] | null | undefined>(null)
  const [ sendDatalayerEvent ] = useDataLayer()
  const familyType = `${page}`

  const urlPJ = 'https://imobiliario.inter.co/home-equity-pj'
  const urlPF = 'https://imobiliario.inter.co/home-equity/'

  const inputMaxValue = PAYROLL_TYPE[page].maxValue

  function changeInput (evt: ChangeEvent<HTMLInputElement>) {
    const inputName = evt.target.name
    let inputValue = evt.target.value

    if (simulationType === 'total') {
      if (currencyToFloat(inputName, inputValue) > inputMaxValue) {
        inputValue = `${inputMaxValue}00`
      }
    }

    const insertValue = currencyToFloat(inputName, inputValue)
    dispatch(actions.setInputValue(insertValue))
  }

  const dataLayerCtaSimularMeuEmprestimo: IDataLayerParams = {
    section: sectionNumber,
    section_name: sectionName,
    element_action: 'click button',
    element_name: 'Simular Meu Empréstimo',
    step: '0',
    redirect_url: isPj ? urlPJ : urlPF,
  }

  useEffect(() => {
    setGclid(getParameterByName('gclid', ''))
    setFbclid(getParameterByName('fbclid', ''))
    setGaid(getCookie('_ga'))
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))
    setUtmTerm(getParameterByName('utm_term', ''))
  }, [])

  function handleButtonClick () {
    const parametersGclid = gclid ? `&gclid=${gclid}` : ''
    const parametersFbclid = fbclid ? `&fbclid=${fbclid}` : ''
    const parametersGaid = gaid ? `&gaid=${gaid}` : ''
    const parametersUtmSource = utmSource ? `&utm_source=${utmSource}` : ''
    const parametersUtmMedium = utmMedium ? `&utm_medium=${utmMedium}` : ''
    const parametersUtmCampaign = utmCampaign ? `&utm_campaign=${utmCampaign}` : ''
    const parametersUtmContent = utmContent ? `&utm_content=${utmContent}` : ''
    const parametersUtmTerm = utmTerm ? `&utm_term=${utmTerm}` : ''

    sendDataLayerEvent({ event: 'click_simulacao' })

    if (typeof window !== 'undefined') {
      const url = `${isPj ? urlPJ : urlPF}?campanha=site-pf?tipoSimulacao=1&valorTotal=${inputValue.toFixed(
        2,
      )}&familia=${familyType}&origem=site${parametersGaid}${parametersGclid}${parametersFbclid}${parametersUtmSource}${parametersUtmMedium}${parametersUtmCampaign}${parametersUtmContent}${parametersUtmTerm}`
      window.location.href = url
      sendDatalayerEvent(dataLayerCtaSimularMeuEmprestimo)
    }
  }

  return (
    <BoxSimulador isPj={isPj}>
      <div className='options mr-lg-0'>
        <div className='row'>
          <div className='text-center w-100'>
            <ValueTitle className='d-block fs-12 lh-14 fs-lg-17 lh-md-21 text-grayscale--400'>
              De quanto você precisa?
            </ValueTitle>

            <ValueWrapper className='d-flex justify-content-center align-items-center mx-auto'>
              <Cifra isError={inputValue < 70000} className='mr-1'>R$</Cifra>
              <InputValue
                isError={inputValue < 70000}
                type='text'
                name='valor'
                value={floatToCurrency(inputValue)}
                onChange={changeInput}
              />
            </ValueWrapper>

            <ValueDescription
              isError={inputValue < 70000}
              className='fs-10 lh-12 fs-lg-12 lh-lg-15 mb-0 mt-1 text-grayscale--400'
            >
              {inputValue < 70000
                ? 'O valor mínimo é de R$70.000,00'
                : 'O valor precisa ser de até 58% do seu imóvel.'}
            </ValueDescription>

            <span className='range-slider py-2 py-lg-3'>
              <input
                type='range'
                name='range'
                tabIndex={-1}
                min={70000}
                max={maxValue}
                value={inputValue}
                onChange={changeInput}
              />
              <span className='slider-container'>
                <span className='bar'>
                  <span style={{ width: `${percentage}%` }} />
                </span>
                <span className='bar-btn' style={{ left: `${percentage}%` }} />
              </span>
            </span>
          </div>

          <div className='w-100 mx-auto justify-content-center text-center pt-2'>
            <button
              onClick={handleButtonClick}
              className='buttonSend fw-600'
              disabled={inputValue < 70000}
            >
              Simular meu empréstimo
            </button>
          </div>
        </div>
      </div>
    </BoxSimulador>
  )
}

NewPayrollSimulatorHomeEquity.defaultProps = {
  section: 'dobra_1',
}

export default NewPayrollSimulatorHomeEquity
