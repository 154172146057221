import React from 'react'
import { FooterInfo } from './style'

const FooterInfos = () => {
  return (
    <FooterInfo>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 py-5'>
            <p className='fs-16 lh-19 text-grayscale--500 my-0'>
              O Empréstimo com Garantia de Imóvel tem prazo mínimo de 24 meses e máximo de 240 meses. Um exemplo desse empréstimo é o seguinte: receber R$ 300.000,00 para pagar em 15 anos (180 meses), com prestação inicial de R$ 4.069,34, taxa de juros de 1,09% a.m.. + IPCA e amortização na Tabela Price. Nesse caso, o Custo Efetivo Total (CET) seria de 15,27% ao ano. Ou receber R$ 300.000,00 para pagar em 10 anos (120 meses), com prestação inicial de R$ 5.675,61, taxa de juros pré fixada de 1,50% a.m. e Sistema de Amortização Price, com Custo Efetivo Total (CET) de 21,48%.
            </p>
          </div>
        </div>
      </div>
    </FooterInfo>
  )
}

export default FooterInfos
