import { breakpoints, device } from 'src/styles/breakpoints'
import styled from 'styled-components'
import BgSm from '../../assets/image/bg-hero-green-sm.png'
import BgMd from '../../assets/image/bg-hero-green-md.png'
import BgLg from '../../assets/image/bg-hero-green-lg.png'
import BgXl from '../../assets/image/bg-hero-green-xl.png'

export const Section = styled.section`
  background: url(${BgSm}) ;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: ${breakpoints.md}){
    background: url(${BgMd}) ;
    background-repeat: no-repeat;
    min-height: 504px;
    background-size: cover;
  }

  @media (min-width: ${breakpoints.lg}){
    background: url(${BgLg}) ;
    background-repeat: no-repeat;
    min-height: 504px;
    background-size: cover;
  }


  @media (min-width: ${breakpoints.xl}){
    background: url(${BgXl}) ;
    background-repeat: no-repeat;
    min-height: 504px;
    background-size: cover;
  }

`
export const BgContainer = styled.div`
  @media ${device.tablet} {
    position: relative;

    .vectorBottom {
      width: 73px;
      position: absolute;
      z-index: 1;
      bottom: -63px;
      left: -30px;
    }

    .vectorTop {
      width: 73px;
      position: absolute;
      z-index: 1;
      top: -46px;
      right: 2px;
    }
  }

  @media ${device.desktopLG} {
    position: relative;

    .vectorBottom {
      width: 73px;
      position: absolute;
      z-index: 1;
      bottom: -39px;
      left: -21px;
    }

    .vectorTop {
      width: 73px;
      position: absolute;
      z-index: 1;
      top: -52px;
      right: -28px;
    }
  }

  @media ${device.desktopXL} {
    position: relative;

    .vectorBottom {
      width: 73px;
      position: absolute;
      z-index: 1;
      bottom: -50px;
      left: -29px;
    }

    .vectorTop {
      width: 73px;
      position: absolute;
      z-index: 1;
      top: -38px;
      right: -27px;
    }
  }

  @media ${device.desktopXXXL} {
    position: relative;

    .vectorBottom {
      width: 73px;
      position: absolute;
      z-index: 1;
      bottom: -50px;
      left: -29px;
    }

    .vectorTop {
      width: 73px;
      position: absolute;
      z-index: 1;
      top: -38px;
      right: 86px;
    }
  }
`
