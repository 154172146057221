import React from 'react'

import Img from 'gatsby-image'

import pageQuery from '../../pageQuery'

const PayAllYourDebts = () => {
  const data = pageQuery()
  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6'>
            <h2 className='fs-20 lh-25 fs-lg-40 lh-lg-50 text-green--500 fw-600 mb-4'>Pague todas as suas dívidas</h2>
            <p className='fs-16 lh-19 fs-xl-18 lh-xl-22 text-grayscale--700 fw-600 mb-4'>Pra que pagar várias dívidas com juros lá em cima, se você pode consolidar tudo num único débito com uma taxa bem pequena?</p>
            <p className='fs-16 lh-19 fs-xl-18 lh-xl-22 text-grayscale--700'>Com o Empréstimo com Garantia de Imóvel pra Empresas, você tem <strong>dinheiro na mão pra quitar todas as suas contas de uma única vez.</strong> E com as menores taxas e parcelas do mercado, você economiza muito mais na sua empresa.</p>
          </div>
          <div className='col-12 col-md-6 col-xl-5 order-first order-md-last mb-3 mb-md-0'>
            <Img fluid={data.payAllYourDebts.fluid} alt='Homem sorrindo e comemorando com papel na mão' />
          </div>
        </div>
      </div>
    </section>
  )
}

export default PayAllYourDebts
