import React, { useState } from 'react'

import { Section, Circle } from './style'

import Img from 'gatsby-image'

import pageQuery from '../../pageQuery'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import investInYourCompany from '../../assets/data/investInYourCompany.json'

import PoupanA_2 from '@interco/icons/build-v4/bidis/v2/products/investimentos/poupança-2'
import Cashback_01 from '@interco/icons/build-v4/bidis/v2/pagamentos/cashback-01'
import Gift from '@interco/icons/build-v4/orangeds/MD/gift'
import Investments from '@interco/icons/build-v4/orangeds/MD/investments'

type InvestInYourCompany = {
  title: string;
  paragraph: string;
}

const InvestInYourCompany = () => {
  const data = pageQuery()
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5 mb-3'>
            <Img fluid={data.investInYourCompany.fluid} alt='Homem sentado, sorrindo, com uma caneta na mão e vários papéis' />
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 lh-30  fs-lg-40 lh-lg-50 text-green--500 fw-600'>Invista na sua empresa</h2>
            <DefaultCarousel
              md={{ items: 1 }}
              lg={{ items: 1 }}
              xl={{ items: 1 }}

            >
              {
                investInYourCompany.map((item: InvestInYourCompany, index: number) => (
                  <>
                    <div className='d-flex'>
                      <Circle active={index === 0}>
                        <PoupanA_2 height={24} width={24} color={index === 0 ? '#ffffff' : '#161616'} />
                      </Circle>
                      <Circle active={index === 1}>
                        <Cashback_01 height={24} width={24} color={index === 1 ? '#ffffff' : '#161616'} />
                      </Circle>
                      <Circle active={index === 2}>
                        <Gift height={24} width={24} color={index === 2 ? '#ffffff' : '#161616'} />
                      </Circle>
                      <Circle active={index === 3}>
                        <Investments height={24} width={24} color={index === 3 ? '#ffffff' : '#161616'} />
                      </Circle>
                    </div>
                    <h2 className='fs-20 lh-25 texte-grayscale--500 fw-600' key={item.paragraph}>{item.title}</h2>
                    <p className='fs-14 lh-17 text-grayscale--700'>{item.paragraph}</p>
                  </>
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default InvestInYourCompany
