import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      simpleLoan: imageSharp(fluid: { originalName: { regex: "/simple-loan-home-equity/" }}) {
        fluid(maxWidth: 445, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      howToApplyForALoan: imageSharp(fluid: { originalName: { regex: "/howToApplyForALoan/" }}) {
        fluid(maxWidth: 543, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      payAllYourDebts: imageSharp(fluid: { originalName: { regex: "/payAllYourDebts/" }}) {
        fluid(maxWidth: 448, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      investInYourCompany: imageSharp(fluid: { originalName: { regex: "/investInYourCompany/" }}) {
        fluid(maxWidth: 448, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
