import React, { useLayoutEffect, useState } from 'react'

import useWidth from 'src/hooks/window/useWidth'

import BarChart from 'src/components/BarChart'
import AnimatedChart from 'src/components/AnimatedChart'

import UTMLink from 'src/components/CallToAction/ActionButtons/UTMLink'
import { Link } from './style'
import { sendDataLayerEvent } from 'src/shared/helpers'

const WIDTH_MD = 768

const Advantageous = () => {
  const [ isMobile, setIsMobile ] = useState(true)
  const width = useWidth(300)

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  return (
    <section className='pb-5 py-md-5'>
      <div className='container'>
        <div className='row d-flex justify-content-between align-items-center'>
          {
            !isMobile && (
              <AnimatedChart grid='col-12 col-md-6 col-lg-5 mt-4 mt-lg-0' id='home-equity-empresas' />
            )
          }
          <div className='col-12 col-md-6 pl-xl-5'>
            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 text-green--500 fw-600 mb-4'>E por que é vantajoso?</h2>
            <p className='fs-16 lh-22 fs-xl-18 lh-xl-22 text-grayscale--700 mb-4'>Porque as taxas e prazos desse crédito são as melhores do mercado. Enquanto alguns empréstimos chegam até 12% de juros ao mês, <strong>a taxa do Inter é a partir de 1,09% ao mês + IPCA.</strong>  Ela também vale se você trouxer seu contrato pra cá. </p>
            <p className='fs-16 lh-22 fs-xl-18 lh-xl-22 text-grayscale--700 mb-4'>Além disso, aqui <strong>o processo de contratação é digital,</strong>  e assim  seu crédito chega mais rápido pra você.</p>
            <p className='fs-16 lh-22 fs-xl-18 lh-xl-22 text-grayscale--700 mt-4'>Simule e consulte a taxa de acordo com seu prazo escolhido.</p>
          </div>
          {
            isMobile && (
              <>
                <BarChart grid='col-12 col-lg-5 mt-lg-0' id='home-equity-empresas' />
                <div className='col-12'>
                  <Link>
                    <UTMLink
                      link='SIMULADOR_HOME_EQUITY_PJ'
                      text='Simular Home Equity'
                      campanha='site-pj'
                      btncolor='button-link text-white rounded-2 mt-2 mb-5 mb-md-0'
                      sendDatalayerEvent={sendDataLayerEvent}
                      dobra='dobra_04'
                      sectionName='E por que é vantajoso?'
                    />
                  </Link>
                </div>
              </>
            )
          }
        </div>
      </div>
    </section>
  )
}

export default Advantageous
