import React from 'react'
import Layout from './../../../../layouts/BaseLayout'

import Hero from './sections/hero/_index'
import SimpleLoan from './sections/simple-loan/_index'
import YourCredit from './sections/your-credit/_index'
import Advantageous from './sections/advantageous/_index'
import HowToApplyForALoan from './sections/how-to-apply-for-a-loan/_index'
import PayAllYourDebts from './sections/pay-all-your-debts/_index'
import InvestInYourCompany from './sections/invest-in-your-company/_index'
import Faq from './sections/faq/_index'
import FooterInfos from './sections/footer-infos/_index'
import SecuredLoanBenefits from './sections/secured-loan-benefits/_index'

import pageContext from './pageContext.json'
import { Wrapper } from './style'

const HomeEquity = () => {
  return (
    <Wrapper>
      <Layout isPJ pageContext={pageContext}>
        <Hero />
        <SimpleLoan />
        <YourCredit />
        <Advantageous />
        <HowToApplyForALoan />
        <PayAllYourDebts />
        <InvestInYourCompany />
        <SecuredLoanBenefits />
        <Faq />
        <FooterInfos />
      </Layout>
    </Wrapper>
  )
}

export default HomeEquity
