import React, { useState, useEffect, SetStateAction, Dispatch } from 'react'
import { EventCaller } from 'inter-site-components'
import * as URLs from 'config/api/Urls'
import { getParameterByName } from 'src/shared/helpers'

export type URLsType = typeof URLs

type UTMLinkProps<K extends keyof URLsType> = {
  t?: () => void;
  text?: string;
  link?: K;
  btncolor: string;
  campanha: string;
  plano?: string;
  setSimulator?: Dispatch<SetStateAction<string>>;
  setSimulatorPortability?: Dispatch<SetStateAction<string>>;
  simulatorValue?: number;
  customParameter?: string;
  dobra: string;
  sectionName: string;
  action?: string;
  sendDatalayerEvent?: Function;
  style?: {};
}
function UTMLink ({ text, link, btncolor, campanha, plano, setSimulator, setSimulatorPortability, simulatorValue, customParameter, dobra, sectionName, action, sendDatalayerEvent, style }: UTMLinkProps<keyof URLsType>) {
  const [ gclid, setGclid ] = useState<string | string[] | null | undefined>(null)
  const [ fbclid, setFbclid ] = useState<string | string[] | null | undefined>(null)
  const [ utm_source, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utm_medium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utm_campaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ utm_content, setUtmContent ] = useState<string | string[] | null | undefined>(null)
  const [ utm_term, setUtmTerm ] = useState<string | string[] | null | undefined>(null)
  const [ is_plano, setIsPlano ] = useState<string | string[] | null | undefined>(null)

  useEffect(() => {
    setGclid(getParameterByName('gclid', ''))
    setFbclid(getParameterByName('fbclid', ''))
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))
    setUtmTerm(getParameterByName('utm_term', ''))
    setIsPlano(plano)
  }, [])

  const setSimulators = (simulator: string, link: string) => {
    if (link === 'SIMULADOR_PORTABILIDADE_IMOBILIARIO' && setSimulatorPortability) {
      setSimulatorPortability(simulator)
    } else if (setSimulator) {
      setSimulator(simulator)
    }
  }

  const returnUrlsLink = <K extends keyof URLsType> (input: K): URLsType[K] => {
    return URLs[input]
  }

  const generateSimulatorLink = <K extends keyof URLsType> (link: K) => {
    const parametersGclid = gclid ? `&gclid=${gclid}` : ''
    const parametersFbclid = fbclid ? `&fbclid=${fbclid}` : ''
    // Gaid removido do link simulador, pois agora não e necessario passar ele para o parametro da url, pois ja esta armazenado no cookie;
    // const parametersGaid = gaid ? `&gaid=${gaid}` : ''
    const parametersUtmSource = utm_source ? `&utm_source=${utm_source}` : ''
    const parametersUtmMedium = utm_medium ? `&utm_medium=${utm_medium}` : ''
    const parametersUtmCampaign = utm_campaign ? `&utm_campaign=${utm_campaign}` : ''
    const parametersUtmContent = utm_content ? `&utm_content=${utm_content}` : ''
    const parametersUtmTerm = utm_term ? `&utm_term=${utm_term}` : ''
    const isCampanha = campanha !== '' ? `campanha=${campanha}` : ''

    const simulator = simulatorValue
      ? `${returnUrlsLink(link)}#/${action}?${isCampanha}${parametersGclid}${parametersFbclid}${parametersUtmSource}${parametersUtmMedium}${parametersUtmCampaign}${parametersUtmContent}${parametersUtmTerm}&valorSolicitado=${simulatorValue}`
      : customParameter
      ? `${returnUrlsLink(link)}?${isCampanha}${parametersGclid}${parametersFbclid}${parametersUtmSource}${parametersUtmMedium}${parametersUtmCampaign}${parametersUtmContent}${parametersUtmTerm}&${customParameter}`
      : `${returnUrlsLink(link)}?${isCampanha}${parametersGclid}${parametersFbclid}${parametersUtmSource}${parametersUtmMedium}${parametersUtmCampaign}${parametersUtmContent}${parametersUtmTerm}`
      setSimulators(simulator, link)
    return simulator
  }

  let linkAction = <div />
  if (link && link.indexOf('SIMULADOR') > -1) {
    linkAction =
      <EventCaller action='sendGAEvent' parameters={`${text};Botao;send`}>
        <a
          style={style}
          href={generateSimulatorLink(link)}
          className={`btn ${btncolor}`} target='_blank' rel='noreferrer' title={text}
          onClick={() => {
            dobra && text && sendDatalayerEvent && sendDatalayerEvent({
             section: dobra,
             section_name: sectionName,
             element_action: 'click button',
             element_name: text,
             redirect_url: generateSimulatorLink(link),
            })
          }}
        >{text}
        </a>
      </EventCaller>
  } else if (link && link.indexOf('INTERCEL') > -1) {
    const parametersUtmSource = utm_source ? `pid=${utm_source}` : 'pid=direto'
    const parametersUtmCampaign = utm_campaign ? `&c=${utm_campaign}` : '&c=direto'
    const parametersPlano = is_plano ? `&af_sub1=${is_plano}` : ''

    linkAction =
      <EventCaller action='sendGAEvent' parameters={`${text};Botao;send`}>
        <a
          style={style}
          href={`${returnUrlsLink(link)}?${parametersUtmSource}${parametersUtmCampaign}&is_retargeting=true&af_dp=bancointer%3A%2F%2Fintercel&=af_force_deeplink=true${parametersPlano}`}
          className={`btn ${btncolor}`}
          target='_blank' rel='noreferrer'
          title={text}
          onClick={() => {
            dobra && text && sendDatalayerEvent && sendDatalayerEvent({
              section: dobra,
              section_name: sectionName,
              element_action: 'click button',
              element_name: text,
              redirect_url: generateSimulatorLink(link),
             })
          }}
        >{text}
        </a>
      </EventCaller>
  }

  return linkAction
}

UTMLink.defaultProps = {
  btncolor: 'btn--orange',
  campanha: '',
}

export default UTMLink
