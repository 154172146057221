import React from 'react'

import { BgContainer, Section } from './styles'

import NewPayrollSimulatorHomeEquity from 'src/components/PayrollSimulator/NewPayrollSimulatorHome'

const Hero = () => {
  return (
    <Section className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row d-flex justify-content-between align-items-center'>
          <div className='col-12 col-md-6 col-lg-7 mb-5 mb-md-0'>
            <h1 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 text-white fw-600 mb-4 mb-md-3'>
              Home Equity: quanto custa investir na sua empresa?
            </h1>
            <p className='fs-16 lh-22 fs-lg-18 lh-lg-22 text-white mb-0 pr-lg-4 pr-xl-0'>
              Com o Empréstimo com Garantia de Imóvel do Inter você aproveita: <span className='fw-700'>empréstimos a partir de R$70 mil com parcelas que cabem no seu bolso.</span>
            </p>
          </div>
          <div className='col-12 col-md-6 col-lg-5'>
            <BgContainer>
              <NewPayrollSimulatorHomeEquity
                page='home_equity'
                isPj={true}
                sectionNumber='dobra_01'
                sectionName='Home Equity: quanto custa investir na sua empresa?'
              />
            </BgContainer>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
