import React from 'react'

import { Section, Circle, Button } from './style'

import Img from 'gatsby-image'

import howToApplyForALoan from '../../assets/data/howToApplyForALoan.json'
import UTMLink from 'src/components/CallToAction/ActionButtons/UTMLink'

import pageQuery from '../../pageQuery'
import { sendDataLayerEvent } from 'src/shared/helpers'

type howToApplyForALoan = {
  title: string;
  paragraph: string;
}

const HowToApplyForALoan = () => {
  const data = pageQuery()
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex justify-content-between align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 mb-3 mb-md-0 order-md-last'>
            <Img fluid={data.howToApplyForALoan.fluid} alt='Empresários sorrindo em reunião' />
          </div>
          <div className='col-12 col-md-6'>
            <h2 className='fs-20 lh-25 fs-md-40 lh-md-50 text-green--500 fw-600 mb-4'>Como solicitar um Empréstimo com Garantia no Inter?</h2>
            <p className='fs-16 lh-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-4'>Seu empréstimo com garantia de imóvel no Inter é simples, digital e rápido! São apenas 4 passos, veja: </p>
            {
                howToApplyForALoan.map((item: howToApplyForALoan, index: number) => (
                  <div className='d-flex' key={item.paragraph}>
                    <Circle>{index + 1}</Circle>
                    <div>
                      <p className='fs-14 lh-17 text-grayscale--700 fw-700 mb-2'>{item.title}</p>
                      <p className='fs-14 lh-17  text-grayscale--500'>{item.paragraph}</p>
                    </div>
                  </div>
                ))
              }
            <Button>
              <UTMLink
                link='SIMULADOR_HOME_EQUITY_PJ'
                text='Simular Home Equity'
                campanha='site-pj'
                btncolor='button-link text-white rounded-2 mt-2 mb-5 mb-md-0'
                sendDatalayerEvent={sendDataLayerEvent}
                dobra='dobra_05'
                sectionName='Como solicitar um Empréstimo com Garantia no Inter?'
              />
            </Button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default HowToApplyForALoan
