import React from 'react'

import { Section } from './style'

import Img from 'gatsby-image'

import pageQuery from '../../pageQuery'

const SimpleLoan = () => {
  const data = pageQuery()
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5 mb-3 order-md-last'>
            <Img fluid={data.simpleLoan.fluid} alt='Fundo verde com um homem sorrindo utilizando uma camisa social e um avental.' />
          </div>
          <div className='col-12 col-md-6 pr-xl-0'>
            <h2 className='fs-20 lh-25 fs-md-40 lh-md-50 text-green--500 fw-600 mb-4 pb-md-2'>Empréstimo <span className='d-md-block d-xl-inline'>simples e fácil</span></h2>
            <p className='fs-16 lh-19 fs-xl-18 lh-xl-22 text-grayscale--500'>O Empréstimo com Garantia de Imóvel do Inter, também conhecido como Home Equity, é uma das modalidades de crédito mais seguras do mercado com a menor taxa. Funciona assim:</p>
            <ul className='pl-2'>
              <li className='fs-16 lh-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-2'>
                Você pede um crédito a partir de R$ 70 mil e utiliza seu imóvel, residencial ou comercial, como garantia de pagamento
              </li>
              <li className='fs-16 lh-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-2'>O imóvel fica como garantia de pagamento sem você precisar vendê-lo ou desocupá-lo;</li>
              <li className='fs-16 lh-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-2'>Você faz os pagamentos mensais em parcelas que cabem no seu bolso.</li>
            </ul>
            <p className='fs-16 lh-19 fs-xl-18 lh-xl-22 text-grayscale--700 fw-600'>Aproveite para investir no desenvolvimento do seu negócio.</p>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default SimpleLoan
