import styled from 'styled-components'

export const Container = styled.div`
  height: 382px;
  position: relative;
  box-shadow: none;
  margin-bottom: 30px;


.animate-content{
  /* display: none !important; */
  width: 100%;
  height: auto ;
  display: flex ;
  justify-content: flex-start ;
  flex-direction: column ;


  .chart {
    animation-duration: 1.2s;
    width: 100%;
    height: 31px;
    border-radius: 0 5px 5px 0;
    flex: 1 ;
    max-height: 31px ;
    margin-left: 0 !important ;
    margin-right: 7px  !important ;
  }



}
.text-chart {
    position: static;
    padding: 0 5px;
    font-size: 14px;
    line-height: 17px ;
  }


.wrapper-chart {
  margin: 15px 0 7px 0;
  overflow: hidden;
  /* position: absolute; */
  max-height: 31px ;
  position: static ;
}

.animated-chart  {
  .animate-content {
    display: flex !important;
    justify-content: flex-start;


    .chart--animate {
      position: relative;
      /* @include animateHeight('home-equity', 0.5, 10, 260); */
    }
  }
}

.percentage-product {
  border-radius: 8px;
  margin: 0 !important;
  padding: 3px;
  line-break: anywhere ;

}


`
