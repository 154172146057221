import styled from 'styled-components'

type CircleProps = {
  active: boolean;
}

export const Section = styled.section`
    background: #F5F6FA ;


    .react-multiple-carousel__arrow {
      svg{
        fill: #07605B ;
      }
    }

    .react-multi-carousel-dot{

      button{
        background-color:#07605B !important;
      }
    }

    .react-multi-carousel-dot--active{

      button{
        background-color: #07605B !important;

      }
    }

`
export const Circle = styled.div`
    background: ${(props: CircleProps) => props.active ? '#07605B' : 'transparent'}  ;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center ;
    margin-bottom: 24px ;

    & + div {
      margin-left: 26px ;
    }
`
