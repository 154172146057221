import React from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import UTMLink from 'src/components/CallToAction/ActionButtons/UTMLink'

import securedLoanBenefits from '../../assets/data/securedLoanBenefits.json'

import ChevronRight from '@interco/icons/build-v4/orangeds/MD/chevron-right'

import { Card, Section, Button } from './style'
import { sendDataLayerEvent } from 'src/shared/helpers'

type SecuredLoanBenefitsProps = {
  icon: string;
  text: string;
}

const SecuredLoanBenefits = () => {
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-lg-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 text-green--500 fw-600 mb-4 text-md-center text-lg-left'>Vantagens do Empréstimo com Garantia de <span className='d-lg-block'>Imóvel do Inter</span> </h2>
            <Button className='d-none d-lg-flex'>
              <UTMLink
                link='SIMULADOR_HOME_EQUITY_PJ'
                text='Simular agora' campanha='site-pj'
                btncolor='button-link text-white rounded-2 mb-md-0'
                sendDatalayerEvent={sendDataLayerEvent}
                dobra='dobra_08'
                sectionName='Vantagens do Empréstimo com Garantia de Imóvel do Inter'
              />
              <ChevronRight height={24} width={24} color='#ffffff' />
            </Button>
          </div>
          <div className='col-12 col-lg-5 col-xl-6'>
            <DefaultCarousel sm={{ items: 1, partialVisibilityGutter: 20 }} md={{ items: 2, partialVisibilityGutter: 50 }} lg={{ items: 1, partialVisibilityGutter: 100 }} xl={{ items: 2 }} itemClass='px-2'>
              {
                securedLoanBenefits.map((item: SecuredLoanBenefitsProps) => (
                  <Card key={item.text}>
                    <OrangeDsIcon icon={item.icon} size='MD' color='#07605B' />
                    <h3 className='fs-20 lh-25 text-grayscale--500'>{item.text}</h3>
                  </Card>
                ))
              }
            </DefaultCarousel>
            <div className='row d-lg-none d-flex justify-content-center mt-4'>
              <div className='col-12 col-md-8'>
                <Button>
                  <UTMLink
                    link='SIMULADOR_HOME_EQUITY_PJ' text='Simular agora' campanha='site-pj'
                    btncolor='button-link text-white rounded-2 mb-md-0'
                    sendDatalayerEvent={sendDataLayerEvent}
                    dobra='dobra_08'
                    sectionName='Vantagens do Empréstimo com Garantia de Imóvel do Inter'
                  />
                  <ChevronRight height={24} width={24} color='#ffffff' />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default SecuredLoanBenefits
