import styled from 'styled-components'
import { gray } from 'src/styles/colors'

export const Wrapper = styled.div`
  main {
    color: ${gray[600]};  
  }
  h1, h2, h3, h4, h5 {
    font-family: 'Sora';
  }
`
